import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getAllowedRoutes } from './auth/allowedRoutes';
import UseCookiesValue from './helpers/useCookiesValue';
import routes from './routes';
import { setUserData } from './store/features/userdataSlice';
import { resetDivisionStore, updatedivisionstore } from './store/features/divisionSlice';
import { useAuth } from './helpers/useAuth';
import HelpShift from './helpers/Helpshift';
import ApiUser from './apis/users';
import { loaderDisable } from './store/features/loaderSlice';
import useApiMessageHandler from './helpers/useApiMessageHandler';
import { setSkipApiResponseProcess } from './store/features/skipApiResponseProcessSlice';
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
 
const CryptoJS = require("crypto-js");
const secure_salt_key = process.env.REACT_APP_SECURE_SALT_KEY;
const SESSION_COOKIES_NAME = process.env.REACT_APP_SESSION_COOKIES_NAME;
const SESSION_TIME_OUT_COOKIES_NAME = process.env.REACT_APP_SESSION_TIME_OUT_COOKIES_NAME;
const BACKEND_BASE_URL = process.env.REACT_APP_FLEET_BACKEND_BASE_URL;
const NO_FLEET_ACCESS_STATUS = process.env.REACT_APP_NO_FLEET_ACCESS_STATUS;

const ProtectedRoutes = (props) => {
  const Api_User = ApiUser();
  const authHelper = useAuth();
  const dispatch = useDispatch();
  const divisionReduxData = useSelector((state) => state.division)[0];
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  let [allowedRoutes, setAllowedRoutes] = useState([]);
  const userdata = useSelector(state => state.userdata);
  const { backendMessagesController, catchErrorsController } = useApiMessageHandler();
 
  const { getCookiesData } = UseCookiesValue();
  const session_cookies = getCookiesData(SESSION_COOKIES_NAME);
  const session_time_out_cookie = getCookiesData(SESSION_TIME_OUT_COOKIES_NAME);
  /*   console.log('---- session_cookies ----', session_cookies); */
  const helpshift = HelpShift();
  
  useEffect(() => { 
    // console.log('Protected Routes');
    if (session_cookies == undefined) { 
      setIsAuth(false);
      authHelper.resetAuth();
      console.log('Session cookies is undefined --- authHelper.resetAuth() triggered (ProtectedRoutes)');
    } else {
  
      if (session_time_out_cookie === undefined) {
        Api_User.getAll()
          .then((response) => {
            dispatch(loaderDisable());
            dispatch(setSkipApiResponseProcess(0));

            let data = response.data;
 
            if (data.status == NO_FLEET_ACCESS_STATUS) {
              navigate(`/accessNotAuth?url=${data.response.d3_url}`);
            } else if (data.status == 1) {

              // if (userdata.length > 0) {
                // if (userdata[0]['access_level'] !== undefined) {
                  const roles = authHelper.access();

                  //////////// walkme ////////////
                  var username = userdata[0].username;
                  var usertype = roles;  //i.e. admin / regular
                  const walkme = document.createElement('walkme');
                  walkme.setAttribute('username', username);
                  walkme.setAttribute('usertype', usertype);
                  document.body.appendChild(walkme);
                  //////////// end walkme ////////////

                  //////////// helpshift walkme ////////////
                  helpshift.load();
                  //////////// helpshift walkme ////////////
 
                  setAllowedRoutes(getAllowedRoutes(routes, roles));
                  setIsAuth(true);
                // } else {
                //   navigate(`/accessNotAuth?url=${data.response.d3_url}`);
                // }
              // } else {
              //   setIsAuth(false);
              //   authHelper.resetAuth();
              // }
            } else {
              console.log('after api response else section --- authHelper.logout() triggered (ProtectedRoutes)');
              authHelper.resetAuth();
            }
            backendMessagesController([data]);
          })
          .catch((errors) => {
            catchErrorsController(" Userdata in Form --- ", errors);
          });
      } else {
        console.log('session_time_out_cookie is true --- authHelper.logout() triggered (ProtectedRoutes)');
        authHelper.resetAuth();
      }
    }

  }, [])
  /// End check login and user session ///

  return isAuth && <DefaultLayout allowedRoutes={allowedRoutes} />

}

export default ProtectedRoutes;
