import { useEffect } from "react";
import { useSelector } from "react-redux";
const helpshift_platformId = process.env.REACT_APP_HELPSHIFT_PLATFORM_ID;
const helpshift_domain = process.env.REACT_APP_HELPSHIFT_DOMAIN;
const helpshift_lang = process.env.REACT_APP_HELPSHIFT_LANG;

function HelpShift(uname, uid, uemail){

    const userdata = useSelector(state=>state.userdata)
    
    const full_name = userdata[0]?.full_name;
    const user_id = userdata[0]?.user_id?.toString();
    const email_id = userdata[0]?.email_id;
    console.log('uname, uid, uemail', full_name, user_id, email_id);
    const load = ()=>{
        // useEffect(()=>{
            let PLATFORM_ID = helpshift_platformId, 
            DOMAIN = helpshift_domain,
            LANGUAGE = helpshift_lang,
            USERID = user_id,
            USEREMAIL = email_id,
            USERNAME = full_name;
    
            window.helpshiftConfig = {
                platformId: PLATFORM_ID,
                domain: DOMAIN,
                language: LANGUAGE,
                userId: USERID,
                userEmail: USEREMAIL,
                userName: USERNAME,
                tags : ["admins","fleet"]
            };
            helpshiftConfig.widgetOptions = { 
                fullScreen: false, 
                position: "bottom-right",
            };
        // },[])
        return !function(t,e){if("function"!=typeof window.Helpshift){var n=function(){n.q.push(arguments)};n.q=[],window.Helpshift=n;var i,a=t.getElementsByTagName("script")[0];if(t.getElementById(e))return;i=t.createElement("script"),i.async=!0,i.id=e,i.src="https://webchat.helpshift.com/latest/webChat.js";var o=function(){window.Helpshift("init")};window.attachEvent?i.attachEvent("onload",o):i.addEventListener("load",o,!1),a.parentNode.insertBefore(i,a)}else window.Helpshift("update")}(document,"hs-chat");
    }

    return {load}

} 

export default HelpShift;